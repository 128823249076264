import React from "react";
import { SelectField, Input, Text } from "@aws-amplify/ui-react";
import { QuarterSpliltScreen } from "./QuarterSplitScreenLayout";
import { I18n } from "aws-amplify";

function FieldSearch({ filter, setFilter, showText }) {
  const handleFieldChange = (event) => {
    const selectedValue = event.target.value;
    let newFilter = { ...filter };

    if (
      selectedValue === "eBoxBasicDocDate" ||
      selectedValue === "eBoxBasicRetentionDate"
    ) {
      newFilter = {
        ...newFilter,
        field: selectedValue,
        option: [
          I18n.get("Equals"),
          I18n.get("Earlier than"),
          I18n.get("Later than"),
        ],
        showDateInput: true,
        selectedOption: I18n.get("Equals"),
        inputValue: "",
      };
    } else if (selectedValue === "eBoxBasicInvoiceAmount") {
      newFilter = {
        ...newFilter,
        field: selectedValue,
        option: [
          I18n.get("Equals"),
          I18n.get("Does not equal"),
          I18n.get("Greater than"),
          I18n.get("Less than"),
        ],
        showDateInput: false,
        selectedOption: I18n.get("Equals"),
        inputValue: "",
      };
    } else {
      newFilter = {
        ...newFilter,
        field: selectedValue,
        option: [
          I18n.get("Contains"),
          I18n.get("Does not contain"),
          I18n.get("Equals"),
          I18n.get("Does not equal"),
          I18n.get("Begins with"),
        ],
        showDateInput: false,
        selectedOption: I18n.get("Contains"),
        inputValue: "",
      };
    }
    setFilter(newFilter);
  };

  const handleOptionChange = (event) => {
    setFilter({
      ...filter,
      selectedOption: event.target.value,
      inputValue: "",
    });
  };

  const handleInputChange = (event) => {
    setFilter({
      ...filter,
      inputValue: event.target.value,
    });
  };

  return (
    <QuarterSpliltScreen
      firstWeight={1}
      secondWeight={1}
      thirdWeight={1}
      fourthWeight={1}
    >
      {showText && (
        <Text className="search-content">
          {I18n.get("Where the property...")}{" "}
        </Text>
      )}
      <SelectField value={filter.field} onChange={handleFieldChange}>
        <option value="select">{I18n.get("(Pick Property)")}</option>
        <option value="eBoxBasicCountryReceiver">
          {I18n.get("Country Receiver")}
        </option>
        <option value="eBoxBasicCountrySender">
          {I18n.get("Country Sender")}
        </option>
        <option value="eBoxBasicCurrency">{I18n.get("Currency")}</option>
        <option value="eBoxBasicPlaceGoodrcv">
          {I18n.get("Delivery Party City")}
        </option>
        <option value="eBoxBasicNameGoodrcv">
          {I18n.get("Delivery Party Name")}
        </option>
        <option value="eBoxBasicDocDate">{I18n.get("Document Date")}</option>
        <option value="eBoxDocumentNumber">{I18n.get("Document No.")}</option>
        <option value="eBoxBasicDocRec">{I18n.get("Document Receiver")}</option>
        <option value="eBoxBasicDocIssuer">
          {I18n.get("Document Sender")}
        </option>
        <option value="eBoxBasicInvoiceAmount">
          {I18n.get("Gross Invoice Value")}
        </option>
        <option value="eBoxBasicOrderNumber">{I18n.get("Order No.")}</option>
        <option value="eBoxBasicPartNoReceiver">
          {I18n.get("Partner No. Receiver")}
        </option>
        <option value="eBoxBasicPartNoSender">
          {I18n.get("Partner No. Sender")}
        </option>
        <option value="eBoxBasicRetentionDate">
          {I18n.get("Retention Date")}
        </option>
      </SelectField>
      <SelectField
        className="dependent-dropdown"
        value={filter.selectedOption}
        onChange={handleOptionChange}
      >
        {filter.option.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </SelectField>
      {filter.showDateInput ? (
        <Input
          type="date"
          value={filter.inputValue}
          onChange={handleInputChange}
        />
      ) : (
        <Input
          type="text"
          value={filter.inputValue}
          onChange={handleInputChange}
        />
      )}
    </QuarterSpliltScreen>
  );
}

export default FieldSearch;
