import React from "react";
import {
  Table,
  TableCell,
  TableBody,
  TableHead,
  TableRow,
  CheckboxField,
} from "@aws-amplify/ui-react";
import { I18n } from "aws-amplify";
import { AiOutlineDownload, AiOutlinePrinter } from "react-icons/ai";
import { BsArrowUpShort, BsArrowDownShort } from "react-icons/bs";

const SearchResults = ({
  results,
  downloadFile,
  downloadZip,
  printPDF,
  printMergedPDFs,
  selectedFiles,
  setSelectedFiles,
  onSortChange,
  sortCriteria,
}) => {
  const fileSelectLimit = 100;

  const handlePrintSelectedPDFs = async () => {
    const pdfFileNames = selectedFiles.map((file) => file.pdf);
    await printMergedPDFs(pdfFileNames);
  };

  //parse files for the AdditionalFiles
  const parseAdditionalFiles = (fileString) => {
    if (!fileString) return [];

    const files = fileString.split(";").filter(Boolean);
    return files
      .map((file) => {
        const fileName = file.split(":")[1];
        return fileName ? `AdditionalFiles/${fileName}` : null;
      })
      .filter(Boolean);
  };

  // handles different file downloads
  const handleDownload = async (e, result) => {
    const isTestEnv = process.env.REACT_APP_TEST === "true";
    switch (e.target.value) {
      case "pdf":
        const pdfFileName = isTestEnv
          ? "pdfFile-test.pdf"
          : result._source.PDFFileName;
        downloadFile(`PDFFiles/${pdfFileName}`);
        break;
      case "signature":
        const signatureFileName = isTestEnv
          ? "signature-test.PDF.pkcs7"
          : result._source.SignatureFileName;
        downloadFile(`AdditionalFiles/${signatureFileName}`);
        break;
      case "report":
        const reportFileName = isTestEnv
          ? "signatureReport-test.html"
          : result._source.SignatureReportName;
        downloadFile(`SignatureReports/${reportFileName}`);
        break;
      case "printPdf":
        printPDF(result._source.PDFFileName);
        break;
      case "additional":
        if (isTestEnv) {
          downloadFile("AdditionalFiles/additionalFile-test2.edi");
          downloadFile("AdditionalFiles/additionalFile-test1.csv");
        } else {
          const additionalFiles = parseAdditionalFiles(
            result._source.AdditionalFiles
          );
          additionalFiles.forEach((file) => downloadFile(file));
        }
        break;
      default:
        console.warn("Unknown option selected");
    }
    e.target.value = "default";
  };

  // Convert contentType Name for result table to display
  const getContentTypeDisplay = (contentType) => {
    switch (contentType) {
      case "invoice":
        return I18n.get("Invoice");
      case "credit note":
        return I18n.get("Credit Note");
      case "debit note":
        return I18n.get("Debit Note");
      case "cancellation credit note":
        return I18n.get("Cancellation Credit Note");
      case "cancellation invoice":
        return I18n.get("Cancellation Invoice");
      case "cancellation debit note":
        return I18n.get("Cancellation Debit Note");
      case "bonus credit note":
        return I18n.get("Bonus Credit Note");
      case "bonus debit note":
        return I18n.get("Bonus Debit Note");
      case "collective invoice":
        return I18n.get("Collective Invoice");
      default:
        return contentType;
    }
  };

  const getStateTypeDisplay = (documentState) => {
    switch (documentState) {
      case "1":
        return I18n.get("New");
      case "2":
        return I18n.get("Read");
      case "3":
        return I18n.get("Downloaded");
      default:
        return documentState;
    }
  };

  function getBrowserLanguage() {
    return navigator.language || navigator.userLanguage;
  }

  // display different date format based on language
  function formatDate(originalDateFormat) {
    const datePart = originalDateFormat.split("T")[0];
    const [year, month, day] = datePart.split("-");

    const language = getBrowserLanguage();

    if (language.startsWith("de")) {
      return `${day}.${month}.${year}`; // German format
    } else {
      return `${month}/${day}/${year}`; // Default to English format
    }
  }

  //display different icons based on sort asc or desc
  const renderSortIcon = (columnName) => {
    if (sortCriteria.column === columnName) {
      return sortCriteria.order === "asc" ? (
        <BsArrowUpShort className="up-icon" />
      ) : (
        <BsArrowDownShort className="down-icon" />
      );
    }
    return null;
  };

  const SortableHeader = ({ columnName, label }) => (
    <TableCell
      as="th"
      className="table-title"
      onClick={() => onSortChange(columnName)}
    >
      {I18n.get(label)} {renderSortIcon(columnName)}
    </TableCell>
  );

  return (
    <Table className="result-table" variation="striped" size="small">
      <TableHead>
        <TableRow>
          <TableCell as="th" colSpan="1">
            <AiOutlineDownload
              className="download-icon"
              onClick={downloadZip}
            />
            <AiOutlinePrinter
              className="download-icon"
              onClick={handlePrintSelectedPDFs}
            ></AiOutlinePrinter>
          </TableCell>
          <TableCell as="th">{I18n.get("Type")}</TableCell>
          <SortableHeader columnName="DocumentNumber" label="Document No." />
          <SortableHeader columnName="DocRec" label="Document Receiver" />
          <SortableHeader columnName="ContentType" label="Content Type" />
          <SortableHeader columnName="ViewStatus" label="Document state" />
          <SortableHeader columnName="DocDate" label="Document Date" />
          <SortableHeader
            columnName="InvoiceAmount"
            label="Gross Invoice Amount"
          />
          <SortableHeader columnName="Currency" label="Currency" />
          <SortableHeader
            columnName="PartNoReceiver"
            label="Partner No. Receiver"
          />
          <SortableHeader
            columnName="PartNoSender"
            label="Partner No. Sender"
          />
          <SortableHeader columnName="DocIssuer" label="Document Sender" />
          <SortableHeader columnName="OrderNumber" label="Order Number" />
          <SortableHeader
            columnName="NameGoodrcv"
            label="Delivery Party Name"
          />
          <SortableHeader
            columnName="PlaceGoodrcv"
            label="Delivery Party City"
          />
          <SortableHeader columnName="RetentionDate" label="End Archiving" />
          <SortableHeader columnName="CommonDocumentNumber" label="Invoice List" />
        </TableRow>
      </TableHead>
      <TableBody>
        {results.map((result) => (
          <TableRow className="result-items" key={result._id}>
            <TableCell>
              <div
                onClick={() => {
                  if (
                    selectedFiles.length >= fileSelectLimit &&
                    !selectedFiles.some(
                      (file) => file.pdf === result._source.PDFFileName
                    )
                  ) {
                    window.alert(
                      I18n.get("You cannot select more than ") +
                        fileSelectLimit +
                        I18n.get(" files!")
                    );
                  }
                }}
              >
                <CheckboxField
                  name="subscribe"
                  value={JSON.stringify({
                    pdf: result._source.PDFFileName,
                    signature: result._source.SignatureFileName,
                    report: result._source.SignatureReportName,
                    additional: result._source.AdditionalFiles
                  })}
                  checked={selectedFiles.some(
                    (file) => file.pdf === result._source.PDFFileName
                  )}
                  disabled={
                    selectedFiles.length >= fileSelectLimit &&
                    !selectedFiles.some(
                      (file) => file.pdf === result._source.PDFFileName
                    )
                  }
                  onChange={(e) => {
                    const fileData = JSON.parse(e.target.value);
                    if (e.target.checked) {
                      setSelectedFiles((prevFiles) => [...prevFiles, fileData]);
                    } else {
                      setSelectedFiles((prevFiles) =>
                        prevFiles.filter((file) => file.pdf !== fileData.pdf)
                      );
                    }
                  }}
                />
              </div>
            </TableCell>
            <TableCell>
              <select
                onChange={(e) => handleDownload(e, result)}
                defaultValue="default"
              >
                <option value="default" disabled>
                  {I18n.get("Select file type")}
                </option>
                <option value="pdf">PDF</option>
                {result._source.SignatureFileName && (
                  <option value="signature">{I18n.get("Signature")}</option>
                )}
                <option value="report">
                  {I18n.get("Verification report")}
                </option>
                <option value="printPdf">{I18n.get("Print")}</option>
                {result._source.AdditionalFiles && (
                  <option value="additional">
                    {I18n.get("Additional Files")}
                  </option>
                )}
              </select>
            </TableCell>
            <TableCell>{result._source.DocumentNumber}</TableCell>
            <TableCell>{result._source.DocRec}</TableCell>
            <TableCell>
              {getContentTypeDisplay(result._source.ContentType)}
            </TableCell>
            <TableCell>
              {getStateTypeDisplay(result._source.ViewStatus)}
            </TableCell>
            <TableCell>{formatDate(result._source.DocDate)}</TableCell>
            <TableCell>{result._source.InvoiceAmount}</TableCell>
            <TableCell>{result._source.Currency}</TableCell>
            <TableCell>{result._source.PartNoReceiver}</TableCell>
            <TableCell>{result._source.PartNoSender}</TableCell>
            <TableCell>{result._source.DocIssuer}</TableCell>
            <TableCell>{result._source.OrderNumber}</TableCell>
            <TableCell>{result._source.NameGoodrcv}</TableCell>
            <TableCell>{result._source.PlaceGoodrcv}</TableCell>
            <TableCell>{formatDate(result._source.RetentionDate)}</TableCell>
            <TableCell>{result._source.CommonDocumentNumber}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default SearchResults;
