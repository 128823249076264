/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_cloud_logic_custom": [
        {
            "name": "documentsShared",
            "endpoint": "https://erdsz57e4f.execute-api.eu-central-1.amazonaws.com/prod",
            "region": "eu-central-1"
        }
    ],
    "aws_cognito_identity_pool_id": "eu-central-1:989f6fd6-3df9-4cfe-ac16-cd73e623405e",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_2Qu6FQhmd",
    "aws_user_pools_web_client_id": "vk6tqc5p9ig85uvatph6etv2j",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_content_delivery_bucket": "shareddocumentsearch-prod-cf-bucket-prod",
    "aws_content_delivery_bucket_region": "eu-central-1",
    "aws_content_delivery_url": "https://d137e7tcolz4yn.cloudfront.net"
};


export default awsmobile;
